<template src="./Popover.html"></template>

<script>
export default {
  name: 'Popover',
  props: {
    config: Object
  },
  methods: {
    documentClick (event) {
      if (
        this.config.closeOnClickOutside &&
        event.target.classList.contains('overlay')
      ) {
        this.config.show = false
        this.$emit('popoverClose')
      }
    },
    windowBlur () {
      if (this.config.closeOnClickOutside) {
        this.config.show = false
        this.$emit('popoverClose')
      }
    }
  },
  created () {
    window.addEventListener('click', this.documentClick)
    window.addEventListener('blur', this.windowBlur)
  }
}
</script>

<style lang="scss" scoped src="./Popover.scss">
</style>
